import ReportDREPage from 'pages/private/reports/screens/dre';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import Page from 'pages/shared/page';
import { Rule } from 'types/permissions';
import ReportPageCreateOrUpdate from 'pages/private/reports/screens/pages/create-or-update';
import ReportPageDetailsPage from 'pages/private/reports/screens/pages/details';
import PageReportsPage from './pages-reports';
import { configReportPages } from './utils';
import ReportProductsPage from 'pages/private/reports/screens/products';
import ReportOrderServicesPage from 'pages/private/reports/screens/order-services';
import ContractsReportPage from 'pages/private/reports/screens/contracts';
import SalesOrdersReportPage from 'pages/private/reports/screens/sales-order';
import ReportResumesPage from 'pages/private/reports/screens/resumes/resumes-reports';
import { resumesBalancesConfigs, resumesReceiptsConfigs } from 'pages/private/reports/screens/resumes/utils';
import { BranchCity } from 'types/models/sales-notes';
import { ClassificationType } from 'types/models/classification';
import ReportPagesGroupedYearsPage from 'pages/private/reports/screens/pages/grouped-years';
import { lazy } from 'react';

const ReportPageCreateBudgetPage = lazy(() => import('pages/private/reports/screens/budgets/create'));
const BudgetReportPage = lazy(() => import('pages/private/reports/screens/budgets/report'));

const [create, update] = configReportPages();

const reports: RouteObject[] = [
    {
        // @ts-ignore
        breadcrumb: 'Relatórios',
        path: 'relatorios',
        element: <Outlet />,
        children: [
            {
                index: true,
                element: <Navigate to="/app" />,
            },
            {
                // @ts-ignore
                breadcrumb: 'DRE',
                path: 'dre',
                element: <Page Component={ReportDREPage} title="DRE" code="dre" permissions={[Rule.List]} />,
            },
            {
                // @ts-ignore
                breadcrumb: 'DRE - Rio de Janeiro',
                path: 'dre-rj',
                element: <Page Component={ReportDREPage} title="DRE - Rio de Janeiro" city={BranchCity.RJ} code="isy-rio-de-janeiro" permissions={[Rule.List]} />,
            },
            {
                // @ts-ignore
                breadcrumb: 'DRE - Pernambuco',
                path: 'dre-pe',
                element: <Page Component={ReportDREPage} title="DRE - Pernambuco" city={BranchCity.PE} code="isy-pernambuco" permissions={[Rule.List]} />,
            },
            {
                // @ts-ignore
                breadcrumb: 'Páginas de Relatório',
                path: 'paginas-de-relatorio',
                element: <Page Component={PageReportsPage} code="paginas-de-relatorio" permissions={[Rule.List]} />,
                children: [
                    {
                        path: 'novo',
                        element: <Page Component={ReportPageCreateOrUpdate} code="paginas-de-relatorio" permissions={[Rule.Create]} withModal={true} {...create} />,
                    },
                    {
                        path: 'editar/:reportPageId',
                        element: <Page Component={ReportPageCreateOrUpdate} code="paginas-de-relatorio" permissions={[Rule.Update]} withModal={true} {...update} />,
                    },
                    {
                        path: ':reportPageId/criar-budget',
                        element: <Page Component={ReportPageCreateBudgetPage} code="relatorio-de-budgets" permissions={[Rule.Create]} withModal={true} />,
                    },
                ],
            },
            {
                path: 'paginas-de-relatorio/:reportPageId',
                element: <Page Component={ReportPageDetailsPage} code="paginas-de-relatorio" permissions={[Rule.Show]} withModal={false} />,
            },
            {
                path: 'paginas-de-relatorio/:reportPageId/agrupados-por-anos',
                element: <Page Component={ReportPagesGroupedYearsPage} code="paginas-de-relatorio" permissions={[Rule.Show]} withModal={false} />,
            },
            {
                //@ts-ignore
                breadcrumb: 'Relatório de budgets',
                path: 'relatorio-de-budgets',
                element: <Page Component={BudgetReportPage} code="relatorio-de-budgets" permissions={[Rule.List]} withModal={false} />,
            },
            {
                // @ts-ignore
                breadcrumb: 'Produtos',
                path: 'produtos',
                element: (
                    <Page
                        Component={ReportProductsPage}
                        title="Produtos"
                        code="relatorios-produtos"
                        endpoint="/reports/products"
                        filtersContentConfig={{
                            classificationType: ClassificationType.ProductClassification,
                            withConsultants: false,
                            isSale: true,
                        }}
                        permissions={[Rule.List]}
                    />
                ),
            },
            {
                // @ts-ignore
                breadcrumb: 'Ordens de serviço',
                path: 'ordens-de-servico',
                element: <Page Component={ReportOrderServicesPage} code="relatorios-ordens-de-servicos" permissions={[Rule.List]} />,
            },
            {
                // @ts-ignore
                breadcrumb: 'Contratos',
                path: 'contratos',
                element: <Page Component={ContractsReportPage} code="relatorios-contratos" permissions={[Rule.List]} />,
            },
            {
                // @ts-ignore
                breadcrumb: 'Pedidos de venda',
                path: 'pedidos-de-venda',
                element: <Page Component={SalesOrdersReportPage} code="relatorios-pedidos-de-venda" permissions={[Rule.List]} />,
            },
            {
                path: 'resumos/receitas',
                element: (
                    <Page
                        key="resumes-report-receipts"
                        Component={ReportResumesPage}
                        configs={resumesReceiptsConfigs}
                        title="Resumos - Receitas"
                        code="relatorios-resumos-receitas"
                        permissions={[Rule.List]}
                    />
                ),
            },
            {
                path: 'resumos/saldos-liquidos',
                element: (
                    <Page
                        key="resumes-report-balances"
                        Component={ReportResumesPage}
                        withFooterClassesByValue={true}
                        configs={resumesBalancesConfigs}
                        title="Resumos - Saldos Líquidos"
                        code="relatorios-resumos-saldos-liquidos"
                        permissions={[Rule.List]}
                    />
                ),
            },
            {
                // @ts-ignore
                breadcrumb: 'Produtos comprados',
                path: 'produtos-comprados',
                element: (
                    <Page
                        Component={ReportProductsPage}
                        title="Produtos comprados"
                        code="relatorios-produtos-comprados"
                        endpoint="/reports/purchase-products"
                        filtersContentConfig={{
                            classificationType: ClassificationType.ProductCategory,
                            isSale: false,
                            withConsultants: false,
                        }}
                        permissions={[Rule.Show]}
                    />
                ),
            },
        ],
    },
];

export default reports;
