import React, { memo } from 'react';
import Select from 'components/core/form/select';
import PageHeader from 'components/page/header';
import Button from 'components/core/button';
import { DREYearsOptions } from '../utils';
import { reportFontsizeButtonClasses } from '../../utils';

type DREHeaderProps = {
    setFontSize?: React.Dispatch<React.SetStateAction<number>>;
    year?: number;
    setYear?: React.Dispatch<React.SetStateAction<number>>;
    title: string;
};

const DREHeader = ({ setFontSize, year, setYear, title }: DREHeaderProps) => {
    return (
        <div className="mb-7 flex flex-col xl:flex-row xl:items-center">
            <PageHeader title={title} className="flex-1 mb-5 xl:mb-0" />
            <div className="flex items-center gap-5">
                {year && setYear && (
                    <div className="flex items-center">
                        <span className={`text-xs text-heading mr-3`}>Ano vigente</span>
                        <Select value={{ value: year, label: year }} options={DREYearsOptions} placeholder="Selecione um ano" onChange={(option: any) => setYear(option.value)} />
                    </div>
                )}
                {!!setFontSize && (
                    <div className="flex items-center gap-2">
                        <Button onClick={() => setFontSize((old) => old - 1)} variant="outlined" className={reportFontsizeButtonClasses}>
                            A-
                        </Button>

                        <Button onClick={() => setFontSize((old) => old + 1)} variant="outlined" className={reportFontsizeButtonClasses}>
                            A+
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(DREHeader);
