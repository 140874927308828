import Modal from 'components/core/modal';
import Spinner from 'components/core/spinner';
import Text from 'components/core/text';
import Empty from 'components/empty';
import Map from 'components/map';
import { useParams } from 'react-router-dom';
import useGetForm from 'services/queries/forms/use-get-form';
import useGetVisit from 'services/queries/visit/use-get-visit';
import { FormFieldType } from 'types/models/form';
import VisitGallery from './visit-gallery';
import { useMemo } from 'react';
import theme from 'settings/theme';
import SvgIcoCheckboxFilled from 'components/core/icon/files/ico-checkbox-filled';
import FieldOption from './item-option';
import classNames from 'classnames';

const fieldsTypesWithOptions = [FormFieldType.Checkbox, FormFieldType.CheckboxWithField, FormFieldType.Radio, FormFieldType.RadioWithField];

const VisitPage = () => {
    const { visitId } = useParams();

    const { data: visit, isLoading: isLoadingVisit } = useGetVisit(Number(visitId));
    const { data: quiz, isLoading: isLoadingForm } = useGetForm(visit?.quizAnswer.quiz.id);

    const isLoading = isLoadingVisit || isLoadingForm;

    const canRenderMap = !!visit?.address.latitude && !!visit?.address.longitude;

    const images = (visit?.files || []).map((image) => image.url);

    const groups = useMemo(() => {
        return (
            quiz?.groups.map((group) => {
                return {
                    ...group,
                    fields: group.fields.map((field) => {
                        const findedAnswer = visit?.quizAnswer.data.find((answer) => answer.id === field.id);

                        const answer = {
                            ...findedAnswer,
                            selectedValue: findedAnswer?.value,
                        };

                        if (!fieldsTypesWithOptions.includes(field.type)) {
                            return { ...field, answer };
                        }

                        return {
                            ...field,
                            answer,
                            options: field.options.map((option) => {
                                return {
                                    ...option,
                                    subfields: option.subfields?.map((subfield) => {
                                        const findedSubfieldAnswer = findedAnswer?.subfields?.find((answerSubfield) => answerSubfield.id === subfield.id);

                                        return {
                                            ...subfield,
                                            selectedValue: findedSubfieldAnswer?.value,
                                        };
                                    }),
                                };
                            }),
                        }!;
                    }),
                };
            }) || []
        );
    }, [quiz?.groups, visit?.quizAnswer.data]);

    return (
        <Modal
            contentClassnames="w-[900px]"
            headerLeft={
                <Text as="h4" variant="h5" className="text-heading">
                    {visit?.quizAnswer.quiz.title || 'Visita'}
                    {!!visit?.code && <small className="text-sm text-base-500 ml-2">#{visit?.code}</small>}
                </Text>
            }
            closeOnClickOutside={false}>
            {isLoading ? (
                <div className="relative p-8">
                    <Spinner />
                </div>
            ) : !visit && !quiz ? (
                <Empty title="Visita não encontrada" />
            ) : (
                <div className="px-6 pb-6">
                    <div className="box-with-border mb-6 grid grid-cols-3 gap-2">
                        {!!visit?.title && (
                            <div>
                                <Text className="text-primary-500 block" as="label" variant="body.medium.2xs">
                                    Nome do campo
                                </Text>
                                <Text className="text-base-500">{visit?.title}</Text>
                            </div>
                        )}
                        {!!visit?.contract && (
                            <div>
                                <Text className="text-primary-500 block" as="label" variant="body.medium.2xs">
                                    Contrato
                                </Text>
                                <Text className="text-base-500">#{visit?.contract}</Text>
                            </div>
                        )}
                        <div>
                            <Text className="text-primary-500 block" as="label" variant="body.medium.2xs">
                                Respondido por
                            </Text>
                            <Text className="text-base-500">{visit?.person.name}</Text>
                        </div>
                    </div>
                    <div className="box-with-border mb-6">
                        <Text className="mb-4 text-primary-500 block" as="label" variant="body.medium.2xs">
                            Respostas
                        </Text>
                        <div>
                            {groups.map((group) => {
                                return (
                                    <div className="box-with-border p-4 mb-4 last:mb-0" key={group.id}>
                                        <Text variant="body.medium.2xs" as="label" className="block mb-4 !font-bold text-secondary-500">
                                            {group.title}
                                        </Text>
                                        {!!group.conditionalFieldTitle && (
                                            <div className="flex-row-center justify-start mb-6">
                                                <Text as="p" className="font-semibold text-base-700 flex-1">
                                                    {group.conditionalFieldTitle}
                                                </Text>
                                                <SvgIcoCheckboxFilled width={20} height={20} color={theme.extend.colors.system.success[500]} />
                                            </div>
                                        )}
                                        <div>
                                            {group.fields.map((field) => {
                                                const hasOptions = fieldsTypesWithOptions.includes(field.type);

                                                return (
                                                    <div className="mb-6 last:mb-0" key={field.id}>
                                                        <Text as="p" className="font-semibold text-base-700 mb-1">
                                                            {field.name}
                                                        </Text>
                                                        {hasOptions ? (
                                                            <div className="mb-1 last:mb-0">
                                                                {field.options.map((option) => (
                                                                    <FieldOption field={field} option={option} key={option.id} />
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            <Text as="p" className={classNames('text-base-500 text-[13px]', !field.answer.selectedValue ? 'italic text-xs' : '')}>
                                                                {field.answer.selectedValue || 'Não informado'}
                                                            </Text>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    {/* <div className="box-with-border mb-6">
                        <Text className="mb-4 text-primary-500 block" as="label" variant="body.medium.2xs">
                            Respostas
                        </Text>
                        <div className="grid grid-rows-none grid-cols-1 gap-4">
                            {visit?.quizAnswer.data.map((item) => {
                                const finded = fields.find((field) => field.id === item.id);

                                if (!finded) {
                                    return null;
                                }

                                return (
                                    <div className="box-with-border" key={item.id}>
                                        <Text className="text-base font-bold mb-1 block text-base-700">{finded.name}</Text>
                                        <Text className="text-base-500">{item.value}</Text>
                                    </div>
                                );
                            })}
                        </div>
                    </div> */}
                    <div className={classNames('box-with-border', Boolean(images.length) ? 'mb-6' : '')}>
                        <Text className="mb-4 text-primary-500 block" as="label" variant="body.medium.2xs">
                            Endereço
                        </Text>
                        {canRenderMap && (
                            <Map
                                mapId={`visit_${visit.id}`}
                                className="mb-3 aspect-video rounded-[14px] overflow-hidden"
                                defaultCenter={{ lat: Number(visit?.address.latitude), lng: Number(visit?.address.longitude) }}
                            />
                        )}
                        <Text className="text-xs text-base-500 leading-4">{visit?.address.full}</Text>
                    </div>
                    {Boolean(images.length) && (
                        <div className="box-with-border">
                            <Text className="mb-4 text-primary-500 block" as="label" variant="body.medium.2xs">
                                Arquivos
                            </Text>
                            <div className="grid gap-4 grid-cols-10">
                                <VisitGallery items={images} />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </Modal>
    );
};

export default VisitPage;
