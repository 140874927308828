import React, { Suspense } from 'react';
import withPagePermissions from 'hocs/with-page-permissions';
import { Rule } from 'types/permissions';
import { CrudPageProps } from 'types/graphql';
import Spinner from 'components/core/spinner';

export type PageProps<T = {}> = {
    Component: React.ElementType;
    permissions: Rule[];
    code: string;
    withModal?: boolean;
    [key: string]: any;
} & CrudPageProps<T>;

const Fallback = ({ withModal = false }: Pick<PageProps<{}>, 'withModal'>) => {
    if (withModal) {
        return <div className="fixed left-0 top-0 w-full h-full bg-base-700/30 z-[9998]" />;
    }

    return (
        <div className="relative w-full h-full">
            <Spinner />
        </div>
    );
};

const Page = <T,>({ Component, code, permissions, withModal = false, ...props }: PageProps<T>) => {
    return (
        <Suspense fallback={<Fallback withModal={withModal} />}>
            <Component code={code} permissions={permissions} withModal={withModal} {...props} />
        </Suspense>
    );
};

export default withPagePermissions(Page);
